import { Grid } from "antd";
import { useMediaQuery } from "react-responsive";

const { useBreakpoint } = Grid;

const useResponsive = () => {
  const breakPoints = useBreakpoint(); //  {xs: boolean, sm, md, lg, xl, xxl}

  const screen = {
    ...breakPoints,
    useMediaQuery,
  };

  return screen;
};

export default useResponsive;
