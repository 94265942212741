import React, { useEffect } from "react";
import { Form, Input, Row, Col } from "antd";
import { formName } from "../../pages/OnBoard";
import AddressInput from "../Input/AddressInput";
import PhoneInput from "../Input/PhoneInput";

const BusinessDetailsForm = (props) => {
  const { form } = props;

  useEffect(() => {
    const formValues = form.getFieldsValue();
    console.log("BusinessDetailsForm formValues", formValues);
    if (formValues) {
      form.setFieldsValue({
        ...formValues,
      });
    }
  }, [form]);

  return (
    <>
      <Form layout="vertical" form={form} name={formName.businessDetails}>
        <div className="right-col">
          <div className="paddingLeft">
            <h4 className="text-white step-heading">Business Details</h4>
          </div>
          <div className="pb-5">
            <div className="paddingLeft fixed-height my-scroller">
              <Row gutter={[15, 12]}>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    name="legal_entity_name"
                    label="Legal Entity Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Legal Entity Name",
                      },
                    ]}
                  >
                    <Input placeholder="Legal Entity Name" required />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    name="name"
                    label="Company Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Company Name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Company Name" required />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    name="acn"
                    label="ACN"
                    rules={[
                      {
                        required: true,
                        message: "Please enter ACN",
                      },
                      {
                        min: 9,
                        message: "ACN must be at least 9 characters",
                      },
                    ]}
                  >
                    <Input
                      maxLength="9"
                      placeholder="Enter ACN"
                      required
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    name="abn"
                    label="ABN"
                    rules={[
                      {
                        required: true,
                        message: "Please enter ABN",
                      },
                      {
                        min: 11,
                        message: "ABN must have 11 characters",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      maxLength="11"
                      placeholder="Enter ABN"
                      required
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Email",
                      },
                      { type: "email", message: "Please enter valid Email" },
                    ]}
                  >
                    <Input placeholder="Email" required />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <PhoneInput
                    form={form}
                    formItem={{
                      name: "phone_number",
                      label: "Phone Number",
                      rules: [
                        {
                          required: true,
                          message: "Please enter your Phone Number",
                        },
                      ],
                    }}
                    placeholder="Phone Number"
                  />
                </Col>
                <Col span={24}>
                  <AddressInput
                    form={form}
                    placeholder="Physical Address"
                    formItemProps={{
                      name: "physical_address",
                      label: "Physical Address",
                      rules: [
                        {
                          required: true,
                          message: "Please enter Physical Address",
                        },
                      ],
                    }}
                  />
                </Col>
                <Col span={24}>
                  <AddressInput
                    form={form}
                    placeholder="Billing Address"
                    formItemProps={{
                      name: "billing_address",
                      label: "Billing Address",
                      rules: [
                        {
                          required: true,
                          message: "Please enter Billing Address",
                        },
                      ],
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default BusinessDetailsForm;
