import { API, API_ENDPOINTS } from "../api-config";

const OnBoardInitiatorAPI = {
  postOnboardInitiator: async (payload) => {
    try {
      const res = await API.post(API_ENDPOINTS.ONBOARD_INITIATOR, payload);
      return res;
    } catch (err) {
      console.log("postOnboardInitiator err", err.response);
      throw err;
    }
  },
};

export default OnBoardInitiatorAPI;
