import React from "react";
import { Steps } from "antd";
import "./Sidebar.scss";

const Sidebar = ({ activeStep }) => {
  const steps = [
    { id: "businessDetails", label: "BUSINESS DETAILS" },
    { id: "productDetails", label: "PRODUCT DETAILS" },
    { id: "traceability", label: " TRACEABILITY GOALS" },
    { id: "supplyChainPartners", label: "SUPPLY CHAIN PARTNERS" },
    { id: "userDetails", label: "USER DETAILS" },
    // { id: "choosePlan", label: "CHOOSE PLAN" },
  ];

  return (
    <div className="sidebar-div">
      <div className="panel-div">
        <div>
          <p className="header-text">Subscribe in 6 Simple steps</p>
          <Steps
            className="customSteps"
            current={activeStep}
            direction="vertical"
            size="small"
            items={steps.map((step, index) => ({
              title: step.label,
              status:
                activeStep === index
                  ? "process"
                  : index < activeStep
                  ? "finish"
                  : "wait",
            }))}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
