import React, { useEffect, useState, useRef } from "react";
import { Input, Form, Select, Button } from "antd";

const CustomValueSelect = (props) => {
  const {
    formItemProps,
    selectProps,
    initialOptions,
    inputPlaceholder,
    btnText,
  } = props;
  const [options, setOptions] = useState([...initialOptions]);
  const [name, setName] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    setOptions([...initialOptions]);
  }, [initialOptions]);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    if (!name) return;
    setOptions((prev) => {
      const newOption = { value: name, label: name };
      return [...prev, newOption];
    });
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Form.Item {...formItemProps}>
      <Select
        dropdownRender={(menu) => (
          <>
            {menu}
            <div className="mt-2 d-flex justify-content-between align-items-center gap-5">
              <Input
                placeholder={inputPlaceholder || "Enter new item"}
                ref={inputRef}
                value={name}
                onChange={onNameChange}
              />
              <Button
                type="primary"
                onClick={addItem}
                style={{ height: "40px" }}
              >
                {btnText || "+ Add item"}
              </Button>
            </div>
          </>
        )}
        options={options}
        {...selectProps}
      />
    </Form.Item>
  );
};

export default CustomValueSelect;
