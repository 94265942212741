import logo from "../../assets/images/logo.svg";
import styled from "styled-components";

const Header = () => {
  return (
    <HeaderDiv>
      {" "}
      <img src={logo} className="logo-img" alt="" />
    </HeaderDiv>
  );
};

export default Header;

const HeaderDiv = styled.div`
  .logo-img {
    width: 93px;
    height: 45px;
    top: 36px;
    left: 40px;
  }
`;
