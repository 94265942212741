import { React } from "react";
import { Row, Col, Form, Input } from "antd";
import { formName } from "../../pages/OnBoard";
import DisplayPanel from "../mainLayout/DisplayPanel";
import styled from "styled-components";
import FileUpload from "../UploadFile/FileUpload";
import { INDUSTRY_CATEGORIES } from "../../Utils/CONSTANTS";
import seafoodFarmedTemplate from "../../assets/files/seafoodFarmedTemplate.xlsx";
import seafoodWildCaughtTemplate from "../../assets/files/seafoodWildCaughtTemplate.xlsx";
import redMeatTemplate from "../../assets/files/redMeatTemplate.xlsx";
import poultryTemplate from "../../assets/files/poultryTemplate.xlsx";
import epcisStandardTemplate from "../../assets/files/epcisStandardTemplate.xlsx";
import pharmaceuticalTemplate from "../../assets/files/pharmaceuticalTemplate.xlsx";
import woodConstruction from "../../assets/files/woodConstruction.xlsx";
import steelConstruction from "../../assets/files/steelConstruction.xlsx";
import concreteConstruction from "../../assets/files/concreteConstruction.xlsx";
import organicTemplate from "../../assets/files/organicTemplate.xls";
import nonOrganicTemplate from "../../assets/files/nonOrganicTemplate.xls";
import { DownloadOutlined } from "@ant-design/icons";

const TraceabilityForm = (props) => {
  const { form, industryDetails } = props;
  const productDetails = industryDetails?.product_details;

  // filter product details based on category
  const uniqueProductDetails =
    Array.isArray(productDetails) &&
    productDetails.filter((item, index) => {
      return (
        productDetails.findIndex(
          (item2) => item2.category === item.category
        ) === index
      );
    });

  console.log("uniqueProductDetails", uniqueProductDetails);

  const renderFile = (category) => {
    switch (category) {
      case INDUSTRY_CATEGORIES.FARMED:
        return seafoodFarmedTemplate;
      case INDUSTRY_CATEGORIES.WILD_CAUGHT:
        return seafoodWildCaughtTemplate;
      case INDUSTRY_CATEGORIES.RED_MEAT:
        return redMeatTemplate;
      case INDUSTRY_CATEGORIES.POULTRY:
        return poultryTemplate;
      case INDUSTRY_CATEGORIES.FRESH_PRODUCE_ORGANIC:
        return organicTemplate;
      case INDUSTRY_CATEGORIES.FRESH_PRODUCE_NON_ORGANIC:
        return nonOrganicTemplate;
      case INDUSTRY_CATEGORIES.PHARMACEUTICAL || INDUSTRY_CATEGORIES.PROSTHESIS:
        return pharmaceuticalTemplate;
      case INDUSTRY_CATEGORIES.WOOD:
        return woodConstruction;
      case INDUSTRY_CATEGORIES.STEEL:
        return steelConstruction;
      case INDUSTRY_CATEGORIES.CONCRETE:
        return concreteConstruction;
      default:
        return epcisStandardTemplate;
    }
  };

  return (
    <>
      <Form layout="vertical" form={form} name={formName.traceability}>
        <div className="right-col">
          <div className="paddingLeft">
            <h4 className="text-white step-heading">Traceability Goals</h4>
          </div>
          <div className="pb-5">
            <div className="paddingLeft fixed-height my-scroller">
              <Row gutter={[15, 12]}>
                <Col span={24}>
                  <SubHeading>
                    Critical Tracking Events (CTE) and Key Data Elements (KDE)
                    Requirements {""}
                  </SubHeading>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="industry"
                    label="Industry"
                    rules={[
                      {
                        required: true,
                        message: "Please select Industry",
                      },
                    ]}
                  >
                    <Input placeholder="Industry" required disabled />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <DisplayPanel
                    // fileName={seafoodTemplate}
                    upperText="This template captures all essential  CTEs and KDEs for standard business process in your industry, including event descriptions, data elements and data types.  Please use this template to specify your CTE and KDEs requirements or add if you need any additional data. This information is crucial for customizing our solution to align with your business needs and traceability goals."
                    lowerText="If you have any questions, require clarifications, or need further assistance, please do not hesitate to reach out."
                    isNotShowDownload={true}
                  />
                </Col>
                <Col span={24}>
                  <label className="text-white">
                    <span style={{ color: "red" }}>* </span>CTE & KDE
                    Requirements
                  </label>
                </Col>
                {/* <Col span={24}>
                  {Array.isArray(uniqueProductDetails) &&
                    Object.values(uniqueProductDetails).map((item, index) => {
                      return (
                        <Row
                          gutter={[15, 12]}
                          key={index}
                          className="align-items-baseline"
                        >
                          <Col span={12}>
                            <a
                              href={renderFile(item.category)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="d-flex align-items-center gap-2">
                                <span style={{ color: "white" }}>
                                  {`${index + 1}.`}{" "}
                                </span>
                                {`Download ${item.category} Template`}{" "}
                                <DownloadOutlined />
                              </div>
                            </a>
                          </Col>
                          <Col span={12}>
                            <FileUpload
                              formItemProps={{
                                name: `${item.category}`,
                                // label: `${item.category}`,
                                rules: [
                                  {
                                    required: true,
                                    message: "Please select File",
                                  },
                                ],
                              }}
                            />
                          </Col>
                        </Row>
                      );
                    })}
                </Col> */}
              </Row>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default TraceabilityForm;

const SubHeading = styled.div`
  font-size: 14px;
  color: var(--white-color);
`;
