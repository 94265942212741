// Only capitalize first letter of each word (the rest letters of the word would be in lowercase)
export const capitalizeFirstOnly = (word) => {
  if (!word) return "";
  // remove underscore
  word = word.replace(/[_-]/g, " ");
  word = word
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");

  return word;
};
