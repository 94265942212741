import React, { useState } from "react";
import { Upload, Image, Form } from "antd";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";
import { message } from "antd";

const ImageUpload = (props) => {
  const { formItemProps } = props;
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file.");
    }

    return false;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <>
      <Form.Item
        {...formItemProps}
        getValueFromEvent={getFile}
        valuePropName="fileList"
      >
        <StyledUpload
          listType="picture-card"
          multiple={false}
          maxCount={1}
          onPreview={handlePreview}
          beforeUpload={beforeUpload}
          accept="image/*"
        >
          <div>
            <PlusOutlined
              style={{
                color: "var(--grey-background-color)",
              }}
            />
            <div
              style={{
                marginTop: 8,
                color: "var(--grey-background-color)",
              }}
            >
              Upload
            </div>
          </div>
        </StyledUpload>
      </Form.Item>
      <Image
        src={previewImage}
        alt="preview"
        hidden
        preview={{
          visible: previewOpen,
          onVisibleChange: setPreviewOpen,
          title: previewTitle,
        }}
      />
    </>
  );
};

export default ImageUpload;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const StyledUpload = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    background-color: transparent;
    border: 1px dashed #5e5e5e;
    border-radius: 5px !important;
  }
`;
