import React, { useState } from "react";
import { Form, Input, Select } from "antd";
import styled from "styled-components";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

const AddressInput = (props) => {
  const { form, formItemProps, placeholder, formListName } = props;
  const { label, ...restFormItemProps } = formItemProps || {};
  const [address, setAddress] = useState("");
  const [inputType, setInputType] = useState("goggle"); // ["goggle", "manual"]

  const handleChange = (address) => {
    console.log("handleChange", address);
  };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      console.log("Selected Address:", address, results, form, formItemProps, [
        formItemProps.name,
      ]);
      if (form && formItemProps) {
        if (Array.isArray(formItemProps.name)) {
          // Handle the second scenario with an array name

          let itemName = formItemProps.name;
          let list = form.getFieldValue(formListName);

          list[itemName[0]] = {
            ...list[itemName[0]],
            [itemName[1]]: address,
          };

          form.setFieldsValue({
            [itemName[formListName]]: list,
          });
        } else {
          // Handle the first scenario with a string name
          form.setFieldsValue({
            [formItemProps.name]: address,
          });
        }
      }

      setAddress(address);
    } catch (error) {
      console.error("Error fetching address details", error);
    }
  };

  return (
    <Form.Item label={label}>
      <Input.Group compact>
        <Selector
          placeholder="Address Input Type"
          value={inputType}
          options={[
            { label: "Search from Google", value: "goggle" },
            { label: "Enter manually", value: "manual" },
          ]}
          onChange={(value) => setInputType(value)}
        />
        {inputType === "manual" ? (
          <Form.Item
            style={{ width: "calc(100% - 200px)" }}
            {...restFormItemProps}
          >
            <StyledInput placeholder={placeholder} className="ant-input" />
          </Form.Item>
        ) : (
          <Form.Item
            style={{ width: "calc(100% - 200px)" }}
            {...restFormItemProps}
          >
            <PlacesAutocomplete
              value={address}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="position-relative">
                  <div className="input_area position-relative">
                    <StyledInput
                      {...getInputProps({
                        placeholder: placeholder || "",
                        className: "ant-input",
                      })}
                    />
                  </div>
                  <div className="autocomplete-dropdown-container">
                    <div className="inner">
                      {loading && <div className="text-white">Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";

                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                            })}
                          >
                            <span className="text-white cursor-pointer">
                              {suggestion.description}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Form.Item>
        )}
      </Input.Group>
    </Form.Item>
  );
};

export default AddressInput;

const Selector = styled(Select)`
  width: 200px;
  .ant-select-selector {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    &::placeholder {
      color: var(--input-placeholder-color);
    }
  }
`;

const StyledInput = styled(Input)`
  width: calc(100% - 200px);
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
`;
