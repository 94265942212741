import "./App.scss";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Result } from "antd";
import MainLayout from "./components/mainLayout/mainLayout";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />} />
          <Route
            path="*"
            element={
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Link to="/">Back Home</Link>}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
