import React from "react";
import styled from "styled-components";
import { InfoCircleOutlined } from "@ant-design/icons";

const DisplayPanel = (props) => {
  const { upperText, lowerText, fileName, isNotShowDownload } = props;
  return (
    <DisplayPanelWrapper>
      <div className="upper">
        <InfoIcon />
        <p>{upperText}</p>
      </div>
      <div className="lower">
        {isNotShowDownload ? null : (
          <a target="_blank" href={fileName} rel="noreferrer">
            <DownloadCSVText>Download Template</DownloadCSVText>
          </a>
        )}
        <p>{lowerText || ""}</p>
      </div>
    </DisplayPanelWrapper>
  );
};

export default DisplayPanel;

const DisplayPanelWrapper = styled.div`
  padding: 20px 40px 20px 50px;
  // background-color: #2A2A2A;
  background-color: #32334d;
  border-radius: var(--border-radius);
  position: relative;

  .upper {
    margin-bottom: 15px;

    p {
      color: var(--input-border-color);
      font-size: 12px;
      font-weight: 700;
    }
  }

  .lower {
    border-top: 1px solid #c6c6c626;
    padding-top: 15px;

    p {
      color: #c7c9d9;
      font-size: 12px;
    }
  }
`;

const DownloadCSVText = styled.p`
  color: var(--primary-color) !important;
  font-size: 14px !important;
`;

const InfoIcon = styled(InfoCircleOutlined)`
  position: absolute;
  left: 10px;
  top: 20px;
  font-size: 25px;
  border-radius: 50%;
  color: #b9b9b9;
`;
