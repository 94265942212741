import { React, useEffect } from "react";
import { Form, Input, Row, Col } from "antd";
import { formName } from "../../pages/OnBoard";
import PhoneInput from "../Input/PhoneInput";
import { capitalizeFirstOnly } from "../../Utils/helper-functions";
import { ROLE } from "../../Utils/CONSTANTS";

const UserDetailsForm = (props) => {
  const { form } = props;

  const defaultUserValues = {
    first_name: undefined,
    last_name: undefined,
    username: undefined,
    phone: undefined,
    user_email: undefined,
    role: ROLE.ADMIN,
  };

  useEffect(() => {
    const formValues = form.getFieldsValue();
    console.log("formValues FormFour", formValues, formValues.userDetails);
    if (!formValues.userDetails) {
      console.log("formValues.userDetails if", defaultUserValues);
      form.setFieldsValue({
        ...defaultUserValues,
        role: capitalizeFirstOnly(defaultUserValues.role),
      });
    } else {
      console.log("formValues.userDetails else", formValues.userDetails);
      form.setFieldsValue({
        ...formValues.userDetails,
        role: capitalizeFirstOnly(formValues.userDetails.role),
      });
    }
  }, [form]);

  return (
    <>
      <Form layout="vertical" form={form} name={formName.userDetails}>
        <div className="right-col">
          <div className="paddingLeft">
            <h4 className="text-white step-heading">Admin User Details</h4>
          </div>
          <div className="pb-5">
            <div className="paddingLeft fixed-height my-scroller">
              <Row gutter={[15, 12]}>
                <Col span={12}>
                  <Form.Item
                    name="username"
                    label="Username"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Username",
                      },
                    ]}
                  >
                    <Input placeholder="Username" required />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="role" label="Role">
                    <Input placeholder="Role" disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="first_name"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter First Name",
                      },
                    ]}
                  >
                    <Input placeholder="First Name" required />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="last_name"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Last Name",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" required />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="user_email"
                    label="Email ID"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Email",
                      },
                      { type: "email", message: "Please enter valid Email" },
                    ]}
                  >
                    <Input placeholder="Email ID" required />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <PhoneInput
                    form={form}
                    formItem={{
                      name: "phone",
                      label: "Phone Number",
                      rules: [
                        {
                          required: true,
                          message: "Please enter your Phone Number",
                        },
                      ],
                    }}
                    placeholder="Phone Number"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default UserDetailsForm;
