import axios from "axios";
import { message } from "antd";

/**
 * Error class that adds custom fields to the error object.
 */
class APIError extends Error {
  status;
  message;
  constructor(status, message) {
    super(message);
    this.status = status;
    this.message = message;
  }
}

/**
 * API is an axios instance with the base URL and headers set.
 */
export const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
});

/**
 * NOTE: This API no need headers
 */

/**
 * Handle the response and the error from the server.
 */
API.interceptors.response.use(
  (response) => {
    console.log("API response", response);
    if (response?.data?.statusCode && response?.data?.statusCode !== "200") {
      const status = Number(response?.data?.statusCode);
      const errBody = response?.data?.body;
      const message = errBody?.status || "";
      // Throw the error including status code and message
      throw new APIError(status, message);
    }

    return response;
  },
  (error) => {
    console.log("API [Error]: ", error);
    if (window.navigator.onLine === false) {
      // network error
      message.error("Network error. Please check your internet connection.");
    }

    return Promise.reject(error);
  }
);

/**
 * API_ENDPOINTS is an object with all the endpoints.
 */
export const API_ENDPOINTS = {
  ONBOARD_INITIATOR: "onboarding-initiator",
};
