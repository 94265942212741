import React, { useState, useEffect } from "react";
import { Form, Row, Col, Switch, Divider, Input, Select, Button } from "antd";
import "./Form.scss";
import { formName } from "../../pages/OnBoard";
import DisplayPanel from "../mainLayout/DisplayPanel";
import FileUpload from "../UploadFile/FileUpload";
import supplyChainPartners from "../../assets/files/supplyChainPartners.xlsx";
import styled from "styled-components";
import { SUPPLY_CHAIN_ROLES } from "../../Utils/CONSTANTS";
import { DeleteOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import PhoneInput from "../Input/PhoneInput";
import AddressInput from "../Input/AddressInput";

const defaultSupplyChainValues = {
  role: undefined,
  business_name: undefined,
  business_email: undefined,
  business_phone: undefined,
  business_address: undefined,
  description: "",
  primary_full_name: "",
  primary_role: "",
  primary_email: "",
  primary_phone_no: "",
};

const { TextArea } = Input;

const SupplyChainPartnersForm = (props) => {
  const { form } = props;

  const [supplyChainPartnerChecked, setSupplyChainPartnerChecked] =
    useState(true);

  useEffect(() => {
    const formValues = form.getFieldsValue();

    console.log("formValues", formValues);
    /// check checked is true or false
    const isSupplyChainPartnerChecked = formValues.need_supply_chain;

    console.log("isSupplyChainPartnerChecked", isSupplyChainPartnerChecked);

    if (isSupplyChainPartnerChecked && formValues.data) {
      console.log("true aa", formValues.data);
      form.setFieldsValue({
        data: [...formValues.data],
        need_supply_chain: true,
      });
      setSupplyChainPartnerChecked(true);
    } else {
      console.log("false aa", defaultSupplyChainValues);
      setSupplyChainPartnerChecked(false);
      form.setFieldsValue({
        data: [defaultSupplyChainValues],
        need_supply_chain: false,
      });
    }
  }, [form]);

  // return (
  //   <>
  //     <Form layout="vertical" form={form} name={formName.supplyChainPartners}>
  //       <div className="right-col">
  //         <div className="paddingLeft">
  //           <h4 className="text-white step-heading">Supply Chain Partners</h4>
  //         </div>
  //         <div className="pb-5">
  //           <div className="paddingLeft fixed-height my-scroller">
  //             <Row gutter={[15, 12]}>
  //               <Col span={24}>
  //                 <DisplayPanel
  //                   fileName={supplyChainPartners}
  //                   upperText="Please use the below template to provide the details about your trading or supply chain partners."
  //                 />
  //               </Col>
  //               <Col span={24}>
  //                 <FileUpload
  //                   formItemProps={{
  //                     name: "supply_chain_file",
  //                     label: "Supply Chain Partners",
  //                   }}
  //                 />
  //               </Col>
  //             </Row>
  //           </div>
  //         </div>
  //       </div>
  //     </Form>
  //   </>
  // );
  return (
    <>
      <Form layout="vertical" form={form} name={formName.supplyChainPartners}>
        <div className="right-col">
          <div className="paddingLeft">
            <HeadingDiv>
              <h4 className="text-white step-heading">Supply Chain Partners</h4>
              <SwitchContainer>
                <Form.Item name="need_supply_chain">
                  <Switch
                    onChange={(checked) => {
                      console.log("checked", checked);
                      setSupplyChainPartnerChecked(checked);
                    }}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={supplyChainPartnerChecked}
                  />
                </Form.Item>
              </SwitchContainer>
            </HeadingDiv>
          </div>
          <div className="pb-5">
            <div className="paddingLeft fixed-height my-scroller">
              {supplyChainPartnerChecked && (
                <Form.List name="data">
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key}>
                          <Divider orientation="left" orientationMargin="0">
                            <div
                              style={{
                                width: "200px",
                                textAlign: "left",
                                fontSize: "14px",
                                color: "#fff",
                              }}
                            >
                              Supply Chain Partner {name + 1}
                            </div>
                          </Divider>
                          {name > 0 && (
                            <div
                              style={{
                                float: "right",
                                marginTop: "-41px",
                                marginRight: "5px",
                                zIndex: 1,
                                position: "relative",
                              }}
                            >
                              <DeleteOutlined
                                style={{
                                  cursor: "pointer",
                                  color: "#c6c6c6",
                                  fontSize: "15px",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  remove(name);
                                }}
                              />
                            </div>
                          )}
                          <div>
                            <Row className="g-4" key={key} gutter={[15, 12]}>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, "role"]}
                                  label="Supply Chain Function"
                                  {...restField}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please select Supply Chain Function",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Supply Chain Function"
                                    options={SUPPLY_CHAIN_ROLES.map((role) => ({
                                      text: role,
                                      value: role,
                                    }))}
                                    required
                                  ></Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, "business_name"]}
                                  label="Business Name"
                                  {...restField}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter Business Name",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Business Name"
                                    required
                                  ></Input>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, "business_email"]}
                                  label="Business Email ID"
                                  {...restField}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter Business Email ID",
                                    },
                                    {
                                      type: "email",
                                      message: "Please enter valid Email",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Email" required></Input>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <PhoneInput
                                  form={form}
                                  formItem={{
                                    name: [name, "business_phone"],
                                    label: "Business Phone Number",
                                    rules: [
                                      {
                                        required: true,
                                        message:
                                          "Please enter your Phone Number",
                                      },
                                    ],
                                  }}
                                  placeholder="Phone Number"
                                />
                              </Col>
                              <Col span={24}>
                                <AddressInput
                                  form={form}
                                  placeholder="Business Address"
                                  formListName="data"
                                  formItemProps={{
                                    name: [name, "business_address"],
                                    label: "Business Address",
                                    rules: [
                                      {
                                        required: true,
                                        message:
                                          "Please enter Business Address",
                                      },
                                    ],
                                  }}
                                />
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  name={[name, "description"]}
                                  label="Product/Service Description"
                                  {...restField}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please enter Product/Service Description",
                                    },
                                  ]}
                                >
                                  <TextArea
                                    placeholder="A brief description of the products or services provided by the partner"
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                    required
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Divider orientation="left" orientationMargin="0">
                              <div
                                style={{ fontSize: "14px", color: "#fff" }}
                              >{`Primary Contact`}</div>
                            </Divider>
                            <Row
                              className="g-4"
                              key={key + 1}
                              gutter={[15, 12]}
                            >
                              <Col span={12}>
                                <Form.Item
                                  name={[name, "primary_full_name"]}
                                  label="Full Name"
                                  {...restField}
                                >
                                  <Input placeholder="Full Name" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, "primary_role"]}
                                  label="Role"
                                  {...restField}
                                >
                                  <Input placeholder="Role" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, "primary_email"]}
                                  label="Email ID"
                                  {...restField}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter Email ID",
                                    },
                                    {
                                      type: "email",
                                      message: "Please enter valid Email ID",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Email ID" required />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <PhoneInput
                                  form={form}
                                  formItem={{
                                    name: [name, "primary_phone_no"],
                                    label: "Phone Number",
                                    rules: [
                                      {
                                        required: true,
                                        message:
                                          "Please enter your Phone Number",
                                      },
                                    ],
                                  }}
                                  placeholder="Phone Number"
                                />
                              </Col>
                            </Row>
                            <Divider
                              orientation="left"
                              orientationMargin="0"
                            ></Divider>
                          </div>
                        </div>
                      ))}
                      <div className="mt-3">
                        <Button
                          className="addProcduct button-trans"
                          onClick={(e) => {
                            e.preventDefault();
                            add();
                          }}
                        >
                          <PlusOutlined /> Add More
                        </Button>
                      </div>
                    </div>
                  )}
                </Form.List>
              )}
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default SupplyChainPartnersForm;

const HeadingDiv = styled.div`
  display: flex;
  align-items: baseline;
  gap: 20px;
`;

const SwitchContainer = styled.div`
  .ant-switch-checked {
    background-color: #4096ff;
  }
`;
