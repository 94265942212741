import React from "react";
import itlogo from "../../assets/images/it_end_logo.svg";
import styled from "styled-components";

const SubscriptionThankForm = () => {
  return (
    <ThankSection className="fixed-height">
      <img src={itlogo} alt="" />
      <p className="thanks my-3">Thank you for subscribing to iTrazo ADI.</p>
      <p className="message-test mt-3">
        We make your supply chain trusted, traceable, transparent.{" "}
      </p>
      <EmailSection>
        <p>
          Your administrator login details will be emailed to you shortly. If
          you have any problems accessing the platform, please email us at
          <span> admin@itrazotracetech.com</span>
        </p>
      </EmailSection>
    </ThankSection>
  );
};

export default SubscriptionThankForm;

const ThankSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  margin: 0 auto;
`;

const EmailSection = styled.div`
  padding: 20px;
  background-color: #3c3e60;
  width: 40%;
  p {
    margin-bottom: 0;
  }
  span {
    color: var(--primary-color);
  }
`;
