import axios from "axios";

const useFileHandler = () => {
  // upload file to s3
  const uploadFileToS3 = async (selectedFile, key) => {
    return new Promise((resolve, reject) => {
      axios
        .put(process.env.REACT_APP_PUBLIC_BUCKET_URL_BASE + key, selectedFile)
        .then((response) => {
          console.log("uploadFileToS3 response", response);
          resolve(response);
        })
        .catch((err) => {
          console.log("uploadFileToS3 err", err);
          reject(err);
        });
    });
  };

  const fileHandler = {
    uploadFileToS3,
  };

  return fileHandler;
};

export default useFileHandler;
