// Desc: Constant variables used in the application
export const ROLE = {
  ADMIN: "admin",
};

export const DEFAULT_MODULE = ["crm", "am", "vct", "ins"];

export const DEFAULT_MODULE_LIST = [
  {
    label: "CRM",
    value: "crm",
  },
  {
    label: "Asset Management",
    value: "am",
  },
  {
    label: "Value Chain Tracking",
    value: "vct",
  },
  {
    label: "Data Insight",
    value: "ins",
  },
];

export const OPTIONAL_MODULE_LIST = [
  {
    label: "Order & Stock Management",
    value: "osm",
  },
  {
    label: "Consumer Engagement",
    value: "ce",
  },
];

export const SUPPLY_CHAIN_ROLES = ["Manufacturer", "Distributor", "Logistics"];

export const INDUSTRY_OPTIONS = {
  SEAFOOD: "Seafood",
  AGRICULTURE: "Agriculture",
  BUILDING_AND_CONSTRUCTION: "Building and Construction",
  HEALTH: "Health",
};

// create a object of all categories

export const INDUSTRY_CATEGORIES = {
  FARMED: "Farmed",
  WILD_CAUGHT: "Wild Caught",
  RED_MEAT: "Red Meat",
  POULTRY: "Poultry",
  FRESH_PRODUCE_ORGANIC: "Fresh Produce Organic",
  FRESH_PRODUCE_NON_ORGANIC: "Fresh Produce Non-Organic",
  PHARMACEUTICAL: "Pharmaceutical",
  PROSTHESIS: "Prosthesis",
  WOOD: "Wood",
  CONCRETE: "Concrete",
  STEEL: "Steel",
};

export const INDUSTRY_BASED_CATEGORY = {
  [INDUSTRY_OPTIONS.SEAFOOD]: [
    { label: "Farmed", value: "Farmed" },
    { label: "Wild Caught", value: "Wild Caught" },
  ],
  [INDUSTRY_OPTIONS.AGRICULTURE]: [
    { label: "Red Meat", value: "Red Meat" },
    { label: "Poultry", value: "Poultry" },
    { label: "Fresh Produce (Organic)", value: "Fresh Produce Organic" },
    {
      label: "Fresh Produce (Non-Organic)",
      value: "Fresh Produce Non-Organic",
    },
  ],
  [INDUSTRY_OPTIONS.BUILDING_AND_CONSTRUCTION]: [
    { label: "Wood", value: "Wood" },
    { label: "Concrete", value: "Concrete" },
    { label: "Steel", value: "Steel" },
  ],
  [INDUSTRY_OPTIONS.HEALTH]: [
    { label: "Pharmaceutical", value: "Pharmaceutical" },
    { label: "Prosthesis", value: "Prosthesis" },
  ],
};

export const EXPECTED_LIFE_PERIOD = [
  { label: "Day(s)", value: "Days" },
  { label: "Week(s)", value: "Weeks" },
  { label: "Month(s)", value: "Months" },
  { label: "Year(s)", value: "Years" },
];
