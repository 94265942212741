import React from "react";
import OnBoard from "../../pages/OnBoard";
import Header from "../Header/Header";

const MainLayout = () => {
  return (
    <div className="d-flex p-5 paddingLeftRight m-0 flex-column">
      <Header />
      <OnBoard />
    </div>
  );
};

export default MainLayout;
