import React from "react";
import { Form } from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styled from "styled-components";

const PhoneInputBox = (props) => {
  const { formItem, placeholder } = props;

  return (
    <Wrapper>
      <Form.Item {...formItem}>
        <PhoneInput
          className={"input-phone-number"}
          defaultCountry="AU"
          onChange={(value) => null}
          placeholder={placeholder}
        />
      </Form.Item>
    </Wrapper>
  );
};

export default PhoneInputBox;

const Wrapper = styled.div`
  .input-phone-number {
    border-radius: 5px;
    border: 1px solid var(--input-border-color);
    width: 100%;
    height: 40px;
    padding: 0.5rem;

    input {
      border-radius: 4px !important;
      // height: 4px !important;
      background: transparent !important;
      border: unset;
      color: white !important;

      &::placeholder {
        color: #8f90a6 !important;
      }

      &:focus {
        outline: none !important;
        border-inline-end-width: 1px;
      }
    }

    .PhoneInputCountrySelectArrow {
      width: 6px;
      height: 6px;
      color: white;
      margin-right: 5px;
    }
  }
`;
