import React from "react";
import { Form, Upload, Button, message } from "antd";
import styled from "styled-components";
import { UploadOutlined } from "@ant-design/icons";

const FileUpload = (props) => {
  const { formItemProps } = props;

  console.log("FileUpload", formItemProps);

  const beforeUpload = (file) => {
    console.log("beforeUpload", file, file.type);
    const isCsvOrXls =
      file.type === "text/csv" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "text/xlsx" ||
      file.type === "application/vnd.ms-excel";
    if (!isCsvOrXls) {
      message.error("You can only upload CSV/XLS/XLSX file.");
    }

    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <>
      <Form.Item
        {...formItemProps}
        getValueFromEvent={normFile}
        valuePropName="fileList"
      >
        <StyledUpload
          beforeUpload={beforeUpload}
          multiple={false}
          maxCount={1}
          accept=".xlsx, .xls, .csv"
        >
          <Button icon={<UploadOutlined />}>Upload File</Button>
        </StyledUpload>
      </Form.Item>
    </>
  );
};

export default FileUpload;

const StyledUpload = styled(Upload)`
  .ant-upload-list .ant-upload-list-item {
    button {
      margin-top: 0px;
    }
  }

  button {
    display: flex;
    align-items: center;
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    margin-top: 10px;

    &:hover {
      background-color: var(--primary-color);
      color: var(--white-color) !important;
      border: none;
    }
  }
`;
